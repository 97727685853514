<template>
    <div v-if="isLoading" v-bind:style="`transform: scaleX(${progress}%)`"></div>
</template>

<script setup lang="ts">
const { progress, isLoading } = useLoadingIndicator()
</script>

<style scoped>
div {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    width: auto;
    height: 3px;
    background: var(--primary-400);
    transform-origin: left;
    transition: transform 0.1s;
    z-index: 999999;
}
</style>